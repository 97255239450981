<template>
  <div class="bottom">
    <div class="bottom-list">
      <div class="bottom-item" v-for="(item, index) in bomList" :key="index">
        <img class="bottom-item-img" :src="item.img" alt="" />
        <div class="bottom-item-title">{{ item.title }}</div>
      </div>
    </div>
    <div class="bottom-text">
      <p>投诉电话：12345</p>
      <p>版权所有：杭州来动网络科技有限公司</p>
      <p>
        <a href="https://beian.miit.gov.cn" target="_blank"> {{ icp }}</a>
      </p>
    </div>
  </div>
</template>
<script setup>
  import { ref } from "vue"
  const bomList = [
    { img: require("../img/bom1.png"), title: "正品采购" },
    { img: require("../img/bom2.png"), title: "专业仓储" },
    { img: require("../img/bom3.png"), title: "金牌服务" },
    { img: require("../img/bom4.png"), title: "荣誉口碑" },
    { img: require("../img/bom5.png"), title: "优良口碑" },
  ]
  const icp = ref("")
  icp.value = gIcpNo
</script>
<style lang="scss" scoped>
  .bottom {
    width: 100%;
    height: 220px;
    background: #2c2c2c;
  }
  .bottom-list {
    width: 800px;
    padding: 15px 80px;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid #ffff;
    justify-content: space-around;
    margin: 0px auto;
  }
  .bottom-item-title {
    color: #fff;
  }
  .bottom-item-img {
    width: 61px;
    height: 61px;
  }
  .bottom-text {
    text-align: center;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
</style>
