<template>
  <div class="layout">
    <div class="layout-contern">
      <div class="layout-contern-logo">来动网络</div>
      <Search />
      <div class="layout-contern-btn" v-if="!isLogin">
        <div class="layout-contern-login" @click="handoleLgionClick">
          <img class="layout-contern-icon" src="./img/user.png" alt="" /> 登录
        </div>
        /
        <div class="layout-contern-enroll" @click="handoleEnrollClick">
          注册
        </div>
      </div>
      <div class="layout-contern-btn" v-else>
        <div class="layout-contern-login" @click="handoleSignOutClick">
          退出登录
        </div>
      </div>
    </div>
    <SidebarMenu :active="active"></SidebarMenu>
    <div></div>
    <!-- 内容区 -->
    <AppMIan />
    <bottom />
  </div>
</template>
<script setup>
  import { useRoute, useRouter } from "vue-router"
  import AppMIan from "./App-Main.vue"
  import SidebarMenu from "./Sidebar/SidebarMenu"
  import Search from "@/components/Search.vue"
  import bottom from "./Sidebar/bottom.vue"
  import { watch, ref, computed, onUnmounted } from "vue"
  import Bus from "@/bus/index"
  const isLogin = ref(false)
  const router = useRouter()
  const handoleLgionClick = () => {
    router.push("/login")
  }
  console.log(isLogin.value)
  const active = ref(0)
  isLogin.value = sessionStorage.isLogin
  console.log(isLogin.value)
  const handoleEnrollClick = () => {
    router.push("/enroll")
  }
  const handoleSignOutClick = () => {
    router.push("/")
    sessionStorage.removeItem("isLogin")
    isLogin.value = sessionStorage.isLogin
  }
  Bus.$on("addAge", (num) => {
    isLogin.value = num
    console.log(num)
  })

  //组件卸载时，取消订阅
  onUnmounted(() => {
    Bus.$off("addAge")
  })
</script>
<style lang="scss" scoped>
  .layout-contern {
    width: 100%;
    padding-left: 350px;
    height: 122px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    &-logo {
      font-size: 66px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ff6000;
      line-height: 100px;
    }
    &-btn {
      width: 100px;
      height: 34px;
      background: #ff8420;
      display: flex;
      margin-left: 20px;
      color: #fff;
      line-height: 34px;
      text-align: center;
      justify-content: space-evenly;
    }
    &-login {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
    }
    &-enroll {
      cursor: pointer;
      font-size: 14px;
    }
    &-icon {
      width: 22px;
      height: 22px;
    }
  }
</style>
